import React from 'react';
import './Admin.css';

function Admin(props) {
  return (
    <div className="admin">
      Pictures.
    </div>
  );
}

export default Admin;
