import React from 'react';
import './Gallery.css';

function Gallery(props) {
  return (
    <div className="Gallery bubble">
      Pictures.
    </div>
  );
}

export default Gallery;
